import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Image, LinkBox, Link, Icon, Box, Section } from "@quarkly/widgets";
import { BsDot } from "react-icons/bs";
const defaultProps = {
	"padding": "70px 0 70px 0",
	"sm-padding": "40px 0 40px 0",
	"quarkly-title": "Footer-21"
};
const overrides = {
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"margin": "0px 0px 30px 0px",
			"href": "#"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://ogiwabehis.com/img/7608679.png",
			"display": "block",
			"width": "60px",
			"margin": "0px 0px 0px 0px",
			"height": "60px"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"md-justify-content": "center",
			"md-align-items": "center",
			"justify-content": "center",
			"align-items": "center",
			"margin": "0px 0px 50px 0px",
			"flex-wrap": "wrap",
			"sm-margin": "0px 0px 36px 0px"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"color": "--darkL2",
			"padding": "6px 12px 6px 12px",
			"margin": "0px 6px 0px 0px",
			"md-margin": "0px 6px 0px 6px",
			"href": "/index",
			"font": "normal 500 16px/1.5 --fontFamily-sans",
			"text-decoration-line": "initial",
			"border-color": "--color-darkL2",
			"letter-spacing": "1px",
			"hover-color": "--primary",
			"children": "HOME"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "bs",
			"icon": BsDot,
			"size": "24px",
			"color": "#b2b8be"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"padding": "6px 12px 6px 12px",
			"margin": "0px 6px 0px 6px",
			"href": "/services",
			"font": "normal 500 16px/1.5 --fontFamily-sans",
			"text-decoration-line": "initial",
			"color": "--darkL2",
			"border-color": "--color-darkL2",
			"letter-spacing": "1px",
			"hover-color": "--primary",
			"children": "SERVICES"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "bs",
			"icon": BsDot,
			"size": "24px",
			"color": "#b2b8be"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"padding": "6px 12px 6px 12px",
			"margin": "0px 6px 0px 6px",
			"href": "/contacts",
			"font": "normal 500 16px/1.5 --fontFamily-sans",
			"text-decoration-line": "initial",
			"color": "--darkL2",
			"border-color": "--color-darkL2",
			"letter-spacing": "1px",
			"hover-color": "--primary",
			"children": "CONTACTS"
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" align-items="center" />
		<LinkBox {...override("linkBox")}>
			<Image {...override("image")} />
		</LinkBox>
		<Box {...override("box")}>
			<Link {...override("link")} />
			<Icon {...override("icon")} />
			<Link {...override("link1")} />
			<Icon {...override("icon1")} />
			<Link {...override("link2")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;